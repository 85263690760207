import { FileDoneOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./index.scss";
import React from "react";
import iconFile from "../../assets/file.png";
function ButtonSubmit(props) {
  const {
    title,
    image,
    color,
    classname,
    onClick,
    loadingButton = false,
    form = "myForm",
    style = {},
  } = props;
  return (
    <Button
      form={form}
      htmlType="submit"
      loading={loadingButton}
      disabled={loadingButton}
      className={`btn-submit ${classname ? classname : ""}`}
      key={title}
      style={{ background: color, ...style }}
      icon={<img src={image} alt="" style={{ marginRight: "5px" }}></img>}
      onClick={onClick}
    >
      {!loadingButton && title}
    </Button>
  );
}

export default ButtonSubmit;
